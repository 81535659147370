export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(app)/(home)": [13,[2,3]],
		"/(app)/(home)/accounts": [14,[2,3]],
		"/(app)/(home)/accounts/[account_id]": [15,[2,4]],
		"/(app)/(home)/accounts/[account_id]/access": [16,[2,4]],
		"/(data-room)/authentication/[magic_link]": [23,[7,8,10],[,9]],
		"/(data-room)/data-room": [24,[7,11]],
		"/(data-room)/data-room/error": [26,[7,11]],
		"/(data-room)/data-room/[data_room_id]": [25,[12]],
		"/(app)/g/[org_url_slug]": [17,[2,5]],
		"/(app)/g/[org_url_slug]/capital-calls": [18,[2,5]],
		"/(app)/g/[org_url_slug]/documents": [19,[2,5]],
		"/(app)/g/[org_url_slug]/documents/[document_id]": [20,[2]],
		"/(app)/g/[org_url_slug]/investments/[investment_id]": [21,[2,6]],
		"/(app)/settings": [22,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';